// @flow
import {useState, useEffect, useMemo} from 'react';
import {jobActionHandlers} from 'hooks';
import {getJobs} from 'service';
import {
    useNotificationContext,
    useDashboardContext,
    useAppContext,
} from 'contexts';
import {genericMessageHandler, usePromise} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useConfirmationDialog} from '../shared';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {flatten} from 'lodash';
import {DateTime} from '@cabinetsbycomputer/datetime';

export const useTimeOfDay = (): string => {
    const hour: number = parseInt(DateTime.now().format('HH').toString());

    if (hour < 12) {
        return 'morning';
    } else if (hour >= 12 && hour < 18) {
        return 'afternoon';
    } else {
        return 'evening';
    }
};

type UpdatesPopupType = {
    showUpdatesInfo: Function,
    showContactsInfo: Function,
    dialog: React$Element<any>,
    messages: string | JSX.Element | JSX.Element[],
    contact: string | JSX.Element | JSX.Element[],
};

export const useUpdatesPopup = (): UpdatesPopupType => {
    const {userProfile} = useAppContext();
    const {dialog, showDialog} = useConfirmationDialog('', '', true);

    const [messages, contact] = useMemo(() => {
        const messages = parseHtmlString(userProfile.manufacturerNews);
        const contact = parseHtmlString(userProfile.manufacturerContactInfo);

        return [messages, contact];
    }, [userProfile]);

    const showUpdatesInfo = () => {
        showDialog({
            title: 'Manufacturer Updates',
            message:
                userProfile.manufacturerNews ||
                'No current News & Updates to display',
            hideYesButton: true,
            hideNoButton: true,
            keyboard: false,
            buttons: [
                {
                    name: 'Ok',
                    show: true,
                },
            ],
        });
    };

    const showContactsInfo = () => {
        showDialog({
            title: 'Manufacturer Contact',
            message: userProfile.manufacturerContactInfo,
            hideYesButton: true,
            hideNoButton: true,
            keyboard: false,
            buttons: [
                {
                    name: 'Ok',
                    show: true,
                },
            ],
        });
    };

    return {
        messages,
        contact,
        showUpdatesInfo,
        showContactsInfo,
        dialog,
    };
};

type DashboardTableConfigType = {
    loader: boolean,
    userName: string,
    jobs: Object,
    isNewCustomer: boolean,
    dialog: React$Element<any>,
};

export const useDashboardTableConfig = (): DashboardTableConfigType => {
    const {setLastJob, setJobInfo} = useDashboardContext();
    const {notify} = useNotificationContext();
    const isSmallDevice = isDeviceSmall();
    const {userProfile, refreshDashboard, successDialog, setSuccessDialog} =
        useAppContext();

    const [limit] = useState<number>(isSmallDevice ? 5 : 10);
    const [jobs, setJobs] = useState({});
    const [_] = useState<Object>({});
    const [loader, setLoader] = useState<boolean>(false);
    const [isNewCustomer, setIsNewCustomer] = useState<boolean>(true);
    const {navigate, showDialog, dialog} = jobActionHandlers();

    useEffect(() => {
        if (successDialog?.length) {
            showDialog({
                title: `We're done!`,
                message: successDialog[0].message,
                keyboard: false,
                setShow: true,
                yes: () => {
                    navigate(successDialog[0].link);
                },
            });

            return () => {
                setSuccessDialog([]);
            };
        }
    }, [successDialog]);

    useEffect(() => {
        let cleanUp = () => {};
        setJobs({});
        setLoader(true);

        const jobPromise = getJobs(limit);

        cleanUp = usePromise(
            ([response]) => {
                setJobs(response);

                setJobInfo({
                    'pending-confirmation-jobs':
                        response.pendingConfirmationJobs.length,
                    'production-jobs': response.productionJobs.length,
                    'quoted-jobs': response.quotedJobs.length,
                    'pending-approval-jobs':
                        response.pendingApprovalJobs.length,
                    'completed-jobs': response.completedJobs.length,
                    'pending-payment-jobs': response.pendingPaymentJobs.length,
                });

                const allJobs = flatten(Object.values(response));

                if (allJobs.length) {
                    const latestJob = allJobs.reduce((a, b) => {
                        // Parse dates and check if they are valid
                        const dateA =
                            a.dateUpdatedDateTime && a.dateUpdatedDateTime.date
                                ? DateTime.parse(a.dateUpdatedDateTime.date)
                                : null;
                        const dateB =
                            b.dateUpdatedDateTime && b.dateUpdatedDateTime.date
                                ? DateTime.parse(b.dateUpdatedDateTime.date)
                                : null;

                        if (!dateA || !dateA.isValid) return b;
                        if (!dateB || !dateB.isValid) return a;

                        return dateB.isBefore(dateA.get()) ? a : b;
                    });

                    setLastJob(latestJob);
                }

                if (
                    response.pendingConfirmationJobs.length ||
                    response.productionJobs.length ||
                    response.quotedJobs.length ||
                    response.pendingApprovalJobs.length ||
                    response.completedJobs.length ||
                    response.pendingPaymentJobs.length
                ) {
                    setIsNewCustomer(false);
                }

                setLoader(false);
            },
            [jobPromise],
            (error) => {
                setLoader(false);
                genericMessageHandler(notify, error);
            }
        );

        return () => {
            cleanUp();
        };
    }, [_, refreshDashboard, successDialog]);
    return {
        loader,
        userName: userProfile.hasOwnProperty('name') ? userProfile.name : '',
        jobs,
        isNewCustomer,
        dialog,
    };
};
