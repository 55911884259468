import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import {
    getProductDetails,
    updateImportedProduct,
    getKDMaxProduct,
} from 'service';
import {
    genericMessageHandler,
    getDoorInfo,
    getPanelValidation,
    useComponentMountedHelper,
    useQueryParams,
    getOptions,
    getCabinetFields,
} from 'shared/helpers';

import {
    useAppContext,
    useJobContext,
    useNotificationContext,
    useProductContext,
} from 'contexts';
import {validateProducts} from 'shared/validator';
import {RECENTLY_ADDED_KEYS} from 'hooks';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'shared/reload/helper/useNavigate';
import {getRequiredReferences} from 'components/customer/Product/helpers/getRequiredReferences';
import {getInitialValues} from 'components/customer/Product/helpers/getInitialValues';
import {mapInitialValues} from 'components/customer/Product/helpers/mapInitialValues';
import {mapDefaults} from 'components/customer/Product/helpers/mapDefaults';
import {getFormSchema} from 'components/customer/Product/helpers/getFormSchema';
import {getTabErrors} from 'components/customer/Product/helpers/getTabErrors';
import {mapProductData} from 'components/customer/Product/helpers/mapProductData';
import {useMaterialValues} from 'components/customer/Product/helpers/useMaterialValues';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {
    invalidateProductAndCost,
    useLazyGetProductDataQuery,
    useLazyGetProductStructureQuery,
    useSaveProductMutation,
} from 'components/customer/Product/store/productApi';
import {cloneDeep} from 'lodash';
import {getMaterialRestrictionDetails} from 'components/customer/Product/helpers/getMaterialRestrictionDetails';
import {checkCabinetTopRestriction} from 'components/customer/Product/helpers/checkCabinetTopRestriction';
import {getFaceHeight} from 'components/customer/Product/Drawer/helpers';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {MaterialType} from 'components/customer/Materials/entity';
import {useGetProductDefaultQuery} from 'components/customer/Product/helpers/useGetProductDefaultQuery';
import {useChangedData} from 'components/customer/Product/helpers/useChangedData';
import {getEdge} from 'components/customer/Materials/store/selectors/edgeSelector';
import {getDoor} from 'components/customer/Materials/store/selectors/doorSelector';
import {selectRunnersLoadingState} from 'components/customer/Product/store/drawerRunnerSlice';
import {getImageDataUrl} from 'components/customer/Preview3DCommon/store/viewerSlice';

export const useProductItem = () => {
    const {
        product: productId,
        cabinetId,
        copy,
        goto,
        height,
        width,
        depth,
        importid,
        validate,
    } = useQueryParams();
    const {jobId, roomId} = useParams();
    const {notify, addMessages, MESSAGE_TYPES} = useNotificationContext();
    const {
        AddToFavourite: addToFavouriteContext,
        validationData,
        setProduct,
        setInitialValuesRef,
        productDataStore,
        drawerRunnerInfoReference,
        simpleShelvesReference,
        addRecentItem,
        productHasDoor,
        isLoading,
        currentTab,
        setCurrentTab,
        formStructure,
        setFormStructure,
        validateField,
        defaultValuesReference,
        materialLoading,
        setMaterialOptions,
        setValidationData,
        showDialog,
        setRoomAssistantContent,
    } = useProductContext();
    const {room, job} = useJobContext();
    const {isMounted} = useComponentMountedHelper();
    const navigate = useNavigate();
    const {setIsMobileMenuVisible} = useAppContext();
    const setMaterials = useMaterialValues();
    const [getProductConfig] = useLazyGetProductStructureQuery();
    const [getProductData] = useLazyGetProductDataQuery();
    const {fetchProductDefaultValues} = useGetProductDefaultQuery();
    const getChangedData = useChangedData({productId});

    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [formSchema, setFormSchema] = useState({});
    const [favouriteButtonDisabled, setFavouriteButtonDisabled] =
        useState(false);
    const [isFavourite, setIsFavourite] = useState(false);
    const [tabErrors, setTabErrors] = useState({});
    const [productDetailCopy, setProductDetailCopy] = useState({});
    const [showRoomDefaultDialog, setShowRoomDefaultDialog] = useState(false);
    const loadingDrawerRunners = useAppSelector(selectRunnersLoadingState);

    const exteriorMaterial = useAppSelector((state) =>
        getMaterial(state, MaterialType.EXTERIOR)
    );

    const carcaseMaterial = useAppSelector((state) =>
        getMaterial(state, MaterialType.CARCASE)
    );

    const exteriorMaterialEdge = useAppSelector((state) =>
        getEdge(state, MaterialType.EXTERIOR)
    );

    const carcaseMaterialEdge = useAppSelector((state) =>
        getEdge(state, MaterialType.CARCASE)
    );

    const selectedDoor = useAppSelector((state) => getDoor(state));
    const imageDataUrl = useAppSelector((state) => getImageDataUrl(state));

    const validationRulesStore = useRef([]);
    const productValidationStore = useRef({});
    const shelvesStructureStore = useRef({});
    const shelfTypes = useRef({});
    const shelfStyles = useRef({});
    const shelfSectionHeight = useRef({});
    const partitionHeightReferenceStore = useRef();
    const formSubmitted = useRef(false);
    const productIdRef = useRef(productId);
    const cabinetIdRef = useRef(cabinetId);
    const [saveProduct] = useSaveProductMutation();
    const dispatch = useAppDispatch();

    const {userProfile} = useAppContext();

    const allow3DView = userProfile?.allow3DView;
    const has3DPreview =
        productDataStore?.current &&
        productDataStore.current?.template_3d &&
        productDataStore.current?.template_3d?.length > 0 &&
        allow3DView;

    const addToFavourite = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setLoading(true);

        setFavouriteButtonDisabled(true);
        await addToFavouriteContext({id: productId}, !isFavourite, true);

        setIsFavourite(!isFavourite);
        setFavouriteButtonDisabled(false);
        setLoading(false);
    };

    const productFieldValidator = (values, formikErrors) => {
        if (validateField.current) {
            try {
                const errors = validateProducts(
                    values,
                    validationRulesStore.current,
                    validationData
                );

                const updatedErrors = errors.map((error) => ({
                    ...error,
                    ...{onlyField: true},
                }));

                if (errors.length > 0) {
                    const tabErrorValues = getTabErrors(
                        formStructure,
                        updatedErrors
                    );

                    addMessages({
                        type: MESSAGE_TYPES.ERROR,
                        messages: updatedErrors,
                    });
                    setTabErrors(tabErrorValues);
                } else {
                    if (
                        typeof formikErrors != 'undefined' &&
                        Object.keys(formikErrors).length
                    ) {
                        return;
                    }

                    addMessages({
                        type: MESSAGE_TYPES.ERROR,
                        messages: [],
                    });
                    setTabErrors({});
                }
            } catch (e) {
                addMessages({
                    type: MESSAGE_TYPES.ERROR,
                    messages: [],
                });
                setTabErrors({});
            }
        }
    };

    const recessedRailsDialog = (navigateCallback) => {
        showDialog({
            title: 'Option Selected: Recessed Handle',
            message:
                "You've just saved an item to your job which includes recessed handle cut outs in the cabinet sides. Would you also like to add in rail for this?",
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: 'Save without adding Rails',
                    show: true,
                    action: () => {
                        navigateCallback();
                    },
                },
                {
                    name: 'View Rail Products',
                    show: true,
                    variant: 'primary-color',
                    action: () => {
                        navigate(
                            `/v2/job/${jobId}/room/${roomId}/product?rails=true`
                        );
                    },
                },
            ],
        });
    };

    const productFormHandler = (values) => {
        if (materialLoading || loadingDrawerRunners) {
            setLoading(true);
            formSubmitted.current = true;

            return;
        }

        addMessages({
            type: MESSAGE_TYPES.ERROR,
            messages: [],
        });
        setTabErrors({});
        const errors = validateProducts(
            values,
            validationRulesStore.current,
            validationData
        );

        if (errors.length == 0) {
            setLoading(true);
            const mappedValues = mapProductData({
                values,
                productDataStore,
                shelvesStructureStore,
                shelfTypes,
                shelfStyles,
            });

            mappedValues.room_id = roomId;
            try {
                if (typeof copy === 'undefined' && cabinetId) {
                    mappedValues.job_cabinet_id = cabinetId;
                }
                let isEmptyData = true;
                let changedData = {};
                if (
                    job.isRoomAssistantEnabled &&
                    userProfile.is_room_assistant_available &&
                    userProfile.room_assistant
                ) {
                    changedData = getChangedData({
                        product: mappedValues,
                        room,
                        materials: {
                            ext: exteriorMaterial,
                            carc: carcaseMaterial,
                            extEdge: exteriorMaterialEdge,
                            carcEdge: carcaseMaterialEdge,
                            door: selectedDoor,
                        },
                    });
                    isEmptyData = changedData.every((change) =>
                        change.items.every(
                            (data) =>
                                Object.keys(data).length == 0 || data.is_saved
                        )
                    );
                    if (changedData && !isEmptyData) {
                        setShowRoomDefaultDialog(true);
                        mappedValues['changed_data'] = changedData;
                        setRoomAssistantContent(mappedValues);
                        return;
                    }
                }
                mappedValues['changed_data'] = changedData;
                productFormSubmitHandler(mappedValues);
            } catch (e) {
                genericMessageHandler(notify, e);
                isMounted.current && setLoading(false);
                formSubmitted.current = false;
            }
        } else {
            const tabErrorValues = getTabErrors(formStructure, errors);
            setTabErrors(tabErrorValues);
            addMessages({type: MESSAGE_TYPES.ERROR, messages: errors});
            setLoading(false);
            formSubmitted.current = false;
        }
    };

    const productFormSubmitHandler = useCallback(
        async (mappedValues) => {
            try {
                const response = await saveProduct({
                    product: {
                        ...mappedValues,
                        ...(has3DPreview ? {imageDataUrl} : {}),
                    },
                }).unwrap();

                addRecentItem(
                    productDataStore.current.type,
                    RECENTLY_ADDED_KEYS.PRODUCT
                );

                genericMessageHandler(
                    notify,
                    {message: 'Product saved'},
                    'success'
                );

                isMounted.current && setLoading(false);
                dispatch(invalidateProductAndCost());

                const hasRailProduct = room?.jobCabinets?.some(
                    ({cabinet}) => !!cabinet?.isRecessedRail
                );

                const shouldShowRecessedRailsDialog =
                    mappedValues?.fingerPull_styles > 0 && !hasRailProduct;

                const layoutPath = `/v2/job/${jobId}/room/${roomId}/layout`;
                const kdmaxImportPath = `/v2/job/${jobId}/room/${roomId}/kdmax-import`;
                const dashboardPath = `/v2/job/${jobId}/room/${roomId}/dashboard?pid=${response.id}`;

                const handleNavigation = (path) => {
                    if (shouldShowRecessedRailsDialog) {
                        recessedRailsDialog(() => {
                            navigate(path);
                        });
                    } else {
                        navigate(path);
                    }
                };

                if (goto) {
                    switch (goto) {
                        case 'tdld':
                            handleNavigation(layoutPath);
                            break;
                        case 'kdmax-import':
                            // This needs to be moved from this file to somewhere sensible
                            await updateImportedProduct(
                                importid,
                                true,
                                true,
                                response.id
                            );
                            handleNavigation(kdmaxImportPath);
                            break;
                    }
                } else {
                    handleNavigation(dashboardPath);
                }
            } catch (e) {
                if (e?.data?.error) {
                    const modifiedError = {...e};
                    modifiedError.message = e.data.error;
                    e = modifiedError;
                }

                genericMessageHandler(notify, e);
                isMounted.current && setLoading(false);
            }
        },
        [job, imageDataUrl]
    );

    const initialLoad = async () => {
        try {
            const defaultsValues = await fetchProductDefaultValues(
                Number(productId)
            );
            const {data: ogProductConfig} = await getProductConfig(
                {cabinetType: parseInt(productId)},
                true
            );
            const {data: productValues} = await getProductData(
                {
                    cabinetId,
                    cabinetType: parseInt(productId),
                    roomId: parseInt(roomId),
                },
                true
            );

            const productConfig = cloneDeep(ogProductConfig);
            const productData = cloneDeep(productValues);
            const materialFields = getCabinetFields(productConfig.structure);
            const {validationData, materialOptions, materials} = setMaterials(
                productData,
                materialFields
            );

            const restrictions = getMaterialRestrictionDetails(
                materials,
                materialFields
            );
            const cabinetTopRestriction = await checkCabinetTopRestriction(
                productData
            );

            if (typeof cabinetTopRestriction !== 'undefined') {
                restrictions.push(cabinetTopRestriction);
            }

            let productDetails;

            if (productData.cabinet && productData.cabinet.attributes) {
                productDetails = cloneDeep(productData.cabinet.attributes);
            } else {
                productDetails = await getProductDetails(parseInt(productId));
            }

            const kdmaxProduct = importid
                ? await getKDMaxProduct(importid)
                : null;

            if (productData && productDetails) {
                setIsFavourite(parseInt(productData.favourites) > 0);
                if (cabinetId) {
                    productData.cabinet = {
                        attributes: productDetails,
                    };
                }
                productDataStore.current = productData;
                productDetails.productConfig = productConfig.structure;
                validationRulesStore.current = productConfig.validation;
                productHasDoor.current = getDoorInfo(productConfig);

                let initialValues = getInitialValues(
                    productDetails.productConfig
                );
                ({
                    drawerRunnerInfoReference:
                        drawerRunnerInfoReference.current,
                    shelfSectionHeight: shelfSectionHeight.current,
                    partitionHeightReferenceStore:
                        partitionHeightReferenceStore.current,
                    shelvesStructureStore: shelvesStructureStore.current,
                    shelfTypes: shelfTypes.current,
                    shelfStyles: shelfStyles.current,
                } = getRequiredReferences(productConfig, initialValues));

                if (!productData.hasOwnProperty('cabinet')) {
                    productDataStore.current.cabinet = {
                        attributes: productDetails,
                    };
                }

                productValidationStore.current = getPanelValidation(
                    productDataStore.current.cabinet.attributes
                );

                setProduct(productDetails);
                setProductDetailCopy(productDetails);
                setInitialValuesRef({...initialValues}); // setting copy of initial values in context reference as we might need to access original values later

                const shelvesOptions = getOptions(
                    'simple_shelves_amount',
                    productConfig.structure
                );
                // sets correct values from room defaults to correct keys
                initialValues = mapInitialValues({
                    initialValues,
                    productData,
                    shelvesStructureStore,
                    simpleShelvesReference,
                    partitionHeightReferenceStore,
                    cabinetId,
                    shelvesOptions,
                    drawerRunnerInfoReference:
                        drawerRunnerInfoReference.current,
                    productConfig: productDetails.productConfig,
                });
                // Overrides room defaults if form fields values are set
                if (typeof cabinetId == 'undefined')
                    initialValues = mapDefaults(initialValues, defaultsValues);

                if (width && initialValues.hasOwnProperty('cabinet_width')) {
                    initialValues.cabinet_width = width;
                }

                if (goto == 'kdmax-import') {
                    initialValues.cabinet_height = height
                        ? height
                        : initialValues.cabinet_height;
                    initialValues.cabinet_width = width
                        ? width
                        : initialValues.cabinet_width;
                    initialValues.cabinet_depth = depth
                        ? depth
                        : initialValues.cabinet_depth;
                    initialValues.cabinet_applied_panel_depth = depth
                        ? depth
                        : initialValues.cabinet_applied_panel_depth;
                    if (
                        kdmaxProduct.hasOwnProperty('shelves') &&
                        kdmaxProduct.shelves
                    ) {
                        initialValues.shelves = kdmaxProduct.shelves;
                        initialValues.cabinet_simple_shelves = false;
                        initialValues.simple_shelves_amount =
                            kdmaxProduct.shelfCount;
                        simpleShelvesReference.current =
                            kdmaxProduct.shelfCount;
                    }
                    if (
                        kdmaxProduct.hasOwnProperty('drawerDetails') &&
                        kdmaxProduct.drawerDetails.length
                    ) {
                        initialValues.cabinet_drawer_gap =
                            productData.cabinet_drawer_gap;
                        initialValues.cabinet_drawer_bottom =
                            kdmaxProduct.drawerDetails.bottomMargin;
                        initialValues.cabinet_drawer_top =
                            productData.cabinet_drawer_top;
                        initialValues.drawer_face_height =
                            kdmaxProduct.drawerDetails.faceDetails;
                    }
                    if (
                        kdmaxProduct.hasOwnProperty('doorDetails') &&
                        kdmaxProduct.doorDetails
                    ) {
                        initialValues.cabinet_door_top =
                            kdmaxProduct.doorDetails.topMargin;
                        initialValues.cabinet_door_bottom =
                            kdmaxProduct.doorDetails.bottomMargin;
                    }

                    if (initialValues.hasOwnProperty('cabinet_panel_length')) {
                        initialValues.cabinet_panel_length = height;
                    }

                    if (initialValues.hasOwnProperty('cabinet_panel_width')) {
                        initialValues.cabinet_panel_width = width;
                    }

                    if (
                        productData?.cabinet?.attributes?.microwave ||
                        productData?.cabinet?.attributes?.oven
                    ) {
                        let totalDrawerHeight =
                            initialValues.cabinet_total_drawer_height;
                        if (productData?.cabinet?.attributes?.microwave) {
                            totalDrawerHeight =
                                height -
                                initialValues.microwave_opening_height -
                                productData['ext_material']['thickness'] * 2;
                        } else if (productData?.cabinet?.attributes?.oven) {
                            totalDrawerHeight =
                                height - initialValues.oven_opening_height;
                        }

                        const drawers = cloneDeep(initialValues.drawers);
                        for (let i = 0; i < drawers.length; i++) {
                            if (drawers[i].id == -1) {
                                const faceHeight = getFaceHeight(
                                    drawers,
                                    i,
                                    totalDrawerHeight,
                                    initialValues.cabinet_drawer_bottom,
                                    initialValues.cabinet_drawer_top,
                                    initialValues.cabinet_drawer_gap,
                                    'drawer_face_height',
                                    'drawer_style'
                                );

                                drawers[i].drawer_face_height = faceHeight;
                            }
                        }

                        initialValues.drawers = drawers;
                    }

                    initialValues.cabinet_note = kdmaxProduct.name;
                }
                setMaterialOptions(materialOptions);
                setValidationData({
                    type: 'multiple',
                    data: validationData,
                });

                defaultValuesReference.current = initialValues;
                validateField.current = true;

                if (materials) {
                    if (materials?.exterior && materialFields.hasExterior) {
                        initialValues.exterior_colour_name =
                            materials.exterior.name;
                    }

                    if (materials?.carcase && materialFields.hasCarcase) {
                        initialValues.carcase_colour_name =
                            materials.carcase.name;
                    }
                }
                setInitialValues(initialValues);
                setFormSchema(
                    getFormSchema(
                        productDetails.productConfig,
                        Object.keys(initialValues)
                    )
                );
                setFormStructure(productConfig.structure); // setting form field structure

                if (restrictions.length > 0) {
                    showDialog({
                        title: 'Unavailable Options',
                        message: (
                            <>
                                <p>
                                    Following options are not available. Please
                                    update these options before saving.
                                </p>
                                <ul>
                                    {restrictions.map((restriction, index) => {
                                        return (
                                            <li key={index}>
                                                {parseHtmlString(restriction)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        ),
                        hideYesButton: true,
                        hideNoButton: true,
                        buttons: [
                            {
                                title: 'Ok',
                                name: 'Ok',
                                show: true,
                            },
                        ],
                    });
                }
            } else {
                genericMessageHandler(notify, {
                    message:
                        'Could not fetch product details, please log out and back in then try again later',
                });
            }

            setLoading(false);
        } catch (errors) {
            setLoading(false);
            genericMessageHandler(notify, errors);
        }
    };

    const submitFormManual = () => {
        const submitButton = document.querySelector('#product-submit-button');

        if (submitButton && !submitButton.disabled) {
            formSubmitted.current = true;
            submitButton.click();
        }
    };

    useEffect(() => {
        if (productDetailCopy?.productConfig) {
            const newSchema = getFormSchema(
                productDetailCopy.productConfig,
                Object.keys(initialValues)
            );
            setFormSchema(newSchema);
        }
    }, [initialValues]);

    useLayoutEffect(() => {
        if (validate && !isLoading && !formSubmitted.current) {
            submitFormManual();
        }
        setIsMobileMenuVisible(false);
    }, [isLoading]);

    useEffect(() => {
        if (productIdRef.current != productId) {
            productIdRef.current = productId;
            setCurrentTab(0);
        }

        if (cabinetIdRef.current != cabinetId) {
            cabinetIdRef.current = cabinetId;
            validationRulesStore.current = [];
            productValidationStore.current = {};
            shelvesStructureStore.current = {};
            shelfTypes.current = {};
            shelfStyles.current = {};
            shelfSectionHeight.current = {};
            partitionHeightReferenceStore.current = undefined;
            formSubmitted.current = false;

            setFormSchema({});
            setFavouriteButtonDisabled(false);
            setIsFavourite(false);
            setTabErrors({});
            setInitialValues({});
        }

        setLoading(true);

        initialLoad();

        return () => {
            addMessages({});
            setTabErrors({});
        };
    }, [productId, cabinetId]);

    useEffect(() => {
        if (!materialLoading && formSubmitted.current) {
            submitFormManual();
        }
    }, [materialLoading]);

    // this effect will submit the form in case drawer runner is still loading
    useEffect(() => {
        if (!loadingDrawerRunners && formSubmitted.current) {
            submitFormManual();
        }
    }, [loadingDrawerRunners]);

    return {
        showRoomDefaultDialog,
        setShowRoomDefaultDialog,
        currentTab,
        setCurrentTab,
        productId,
        initialValues,
        formSchema,
        formStructure,
        loading,
        setLoading,
        productFormHandler,
        isFavourite,
        favouriteButtonDisabled,
        addToFavourite,
        productFieldValidator,
        tabErrors,
        setTabErrors,
        productFormSubmitHandler,
    };
};
